<template>
	<main v-if="!loading" id="content" class="bg-map">
		<section class="container-fluid pt-5 pb-5">
			<template v-if="page">
				<article class="container" v-html="page.content" />
			</template>

			<template v-if="state == '404'">
				<article class="container">
					<h1>Pagina niet gevonden</h1>

					<p>
						U kunt de informatie die u zoekt mogelijk vinden via de
						<router-link :to="'/'"> homepagina </router-link> of de
						<router-link :to="'/zoeken'"> zoekpagina </router-link>.
					</p>
				</article>
			</template>
		</section>
	</main>
</template>

<script>
export default {
	components: {
		// 'kodo-my-component': MyComponent
	},

	props: {
		// myProp:      { type: String, required: true }
		// secondProp:  { type: Number, required: false }
	},

	data: function () {
		return {
			page: null,
			state: 200,
		};
	},

	computed: {},

	watch: {},

	created: function () {},

	mounted: function () {
		var path = this.$route.path;
		path = path.replace("/", "");

		this.$store
			.dispatch("fetchPage", path)
			.then((response) => {
				this.page = response.data;
			})
			.catch(() => {
				this.state = 404;
			})
			.finally(() => {
				this.toggleLoading();
			});
	},

	methods: {},

	//updated: function() {}
};
</script>
