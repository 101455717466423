import { createStore } from "vuex";
import _ from "lodash";
import axios from "./axios";

export default createStore({
	state: {
		// Contains all domains.
		domains: [],

		// Contains all (main) ministries.
		ministries: [],

		// All pages.
		pages: [],

		serviceProviderLocations: [
			{
				id: 1,
				name: "Den Haag",
				x: "20%",
				y: "53%",

				service_providers: [
					{
						id: 2,
						name: "IBI/DCI",
						color: ["donkergeel", "rood"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
					{
						id: 190,
						name: "UBR | IIR",
						color: ["donkergroen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["inkoop"],
					},
					{
						id: 190,
						name: "UBR | KOOP",
						color: ["donkergroen"],
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["inkoop"],
					},

					{
						id: 190,
						name: "Nationaal Archief",
						color: ["paars"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["inkoop"],
					},
					{
						id: 2,
						name: "Doc-Direct",
						color: ["donkergroen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["i-ict", "facilitair"],
					},
					{
						id: 190,
						name: "CIBG (Dienst voor Registers)",
						color: ["oranje"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["inkoop"],
					},
					{
						id: 2,
						name: "3R",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [],
						domeinen: ["i-ict", "facilitair"],
					},
					{
						id: 190,
						name: "DICTU",
						color: ["rood"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"bz",
							"bzk",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["inkoop"],
					},
					{
						id: 2,
						name: "Logius",
						color: ["donkergroen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["i-ict", "facilitair"],
					},
					{
						id: 190,
						name: "SSC-ICT",
						color: ["donkergroen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["inkoop"],
					},
					{
						id: 190,
						name: "RWS CIV",
						color: ["donkergeel"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bzk",
							"def",
							"ezk",
							"i&w",
							"j&v",
							"lnv",
							"vws",
						],
						domeinen: ["inkoop"],
					},
					{
						id: 2,
						name: "ODC Haaglanden",
						color: ["donkergroen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["i-ict", "facilitair"],
					},
					{
						id: 190,
						name: "Dienst DigiJust",
						color: ["hemelblauw"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bzk", "j&v"],
						domeinen: ["inkoop"],
					},
				],
			},
			{
				id: 2,
				name: "Utrecht",
				x: "54%",
				y: "53%",

				service_providers: [
					{
						id: 2,
						name: "Utrecht",
						color: ["donkergeel"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
					{
						id: 2,
						name: "Bilthoven",
						color: ["donkergeel"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},

					{
						id: 190,
						name: "Soesterberg",
						color: ["donkergroen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: [
							"az",
							"bz",
							"bzk",
							"def",
							"ezk",
							"fin",
							"i&w",
							"j&v",
							"lnv",
							"ocw",
							"szw",
							"vws",
						],
						domeinen: ["inkoop"],
					},
				],
			},
			{
				id: 2,
				name: "Groningen",
				x: "83%",
				y: "11%",

				service_providers: [
					{
						id: 2,
						name: "Groningen",
						color: ["donkergeel"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Assen",
				x: "83%",
				y: "20%",

				service_providers: [
					{
						id: 2,
						name: "Assen",
						color: ["rood"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Veenhuizen",
				x: "75%",
				y: "19%",

				service_providers: [
					{
						id: 2,
						name: "Veenhuizen",
						color: ["rood"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Zwolle",
				x: "74%",
				y: "35%",

				service_providers: [
					{
						id: 2,
						name: "Zwolle",
						color: ["rood"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Winschoten",
				x: "94%",
				y: "14%",

				service_providers: [
					{
						id: 2,
						name: "Winschoten",
						color: ["rood", "roze"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Bonaire",
				x: "4%",
				y: "96%",

				service_providers: [
					{
						id: 2,
						name: "Bonaire",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Gouda",
				x: "46%",
				y: "58%",

				service_providers: [
					{
						id: 2,
						name: "Gouda",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Tilburg",
				x: "50%",
				y: "73%",

				service_providers: [
					{
						id: 2,
						name: "Tilburg",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Arnhem",
				x: "71%",
				y: "57%",

				service_providers: [
					{
						id: 2,
						name: "Arnhem",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Apeldoorn",
				x: "73%",
				y: "49%",

				service_providers: [
					{
						id: 2,
						name: "Apeldoorn",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Breda",
				x: "41%",
				y: "72%",

				service_providers: [
					{
						id: 2,
						name: "Breda",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Roermond",
				x: "74%",
				y: "83%",

				service_providers: [
					{
						id: 2,
						name: "Roermond",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Roermond",
				x: "74%",
				y: "83%",

				service_providers: [
					{
						id: 2,
						name: "Roermond",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			},
			{
				id: 2,
				name: "Heerlen",
				x: "74%",
				y: "97%",

				service_providers: [
					{
						id: 2,
						name: "Heerlen",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"],
					},
				],
			} /*,
			{
				id: 2,
				name: "Amsterdam",
				x: "43%",
				y: "41%",

				service_providers: [
					{
						id: 2,
						name: "Amsterdam",
						color: ["groen"], // probably definend by ministry, only support for 2 colors atm. if more then the frontend fails.
						ministries: ["bz", "bzk", "ezk", "i&w"],
						domeinen: ["i-ict", "facilitair"]
					}
				]
			}*/,
		],
	},

	getters: {
		domains: (state) => {
			return state.domains;
		},

		domainByAlias: (state) => (alias) => {
			return _.find(state.domains, { alias: alias });
		},

		domainById: (state) => (id) => {
			return _.find(state.domains, { id: id });
		},

		pageByAlias: (state) => (alias) => {
			return _.find(state.pages, { alias: alias });
		},

		serviceProviderLocations: (state) => {
			return state.serviceProviderLocations;
		},

		serviceProvidersByDomainAlias: (state) => (alias) => {
			return _.find(state.serviceProviderLocations.service_providers, {
				domeinen: alias,
			});
		},

		ministries: (state) => {
			return state.ministries;
		},

		ministryByAlias: (state) => (alias) => {
			return _.find(state.ministries, { alias: alias });
		},

		ministryById: (state) => (id) => {
			return _.find(state.ministries, { id: id });
		},
	},

	mutations: {
		setPage: (state, data) => {
			// Push the page.
			state.pages.push(data);
		},

		setDomains: (state, data) => {
			state.domains = data;
		},

		setMinistries: (state, data) => {
			// Loop through the data and remove the ministry with the id 0
			// = Onderdeel Hoge Colleges van Staat + Eerste en Tweede Kamer
			// we love underscore!
			data = _.reject(data, function (el) {
				return el.id === 0;
			});

			data.forEach((d) => {
				d.units = _.orderBy(d.units, ["name"], ["asc"]);
			});

			// Save the state.
			state.ministries = data;
		},
	},

	actions: {
		// Method to fetch all domains from the api.
		loadDomains: (context) => {
			return new Promise((resolve, reject) => {
				axios
					.get("/domains")
					.then((response) => {
						context.commit("setDomains", response.data);
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		// Method to fetch all ministries from the api.
		loadMinistries: (context) => {
			return new Promise((resolve, reject) => {
				axios
					.get("/ministries")
					.then((response) => {
						context.commit("setMinistries", response.data);
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		// Method to search (also a fetch)
		fetchSearchResults: (context, searchParams) => {
			var query = [];
			// Glue search params.
			for (var param in searchParams) {
				if (searchParams.hasOwnProperty(param)) {
					if (searchParams[param]) {
						query.push(
							encodeURIComponent(param) +
								"=" +
								encodeURIComponent(searchParams[param])
						);
					}
				}
			}

			return new Promise((resolve, reject) => {
				axios
					.get("/search?" + query.join("&"))
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		// Method to fetch all service providers from a certain domain.
		fetchServiceProvidersFromDomain: (context, alias) => {
			return new Promise((resolve, reject) => {
				axios
					.get("/domains/" + alias + "/service-providers")
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		// Method to fetch the details from a ministry.
		fetchMinistryOrganisationalUnits: (context, alias) => {
			return new Promise((resolve, reject) => {
				axios
					.get("/ministries/" + alias + "/organisational-units")
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		// Method to fetch all domains from the api.
		fetchPage: (context, alias) => {
			return new Promise((resolve, reject) => {
				axios
					.get("/pages/" + alias)
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
});
