<template>
	<main v-if="!loading" id="content">
		<!-- Only show the map / list from the domain, when we are not comparing -->
		<div
			class="container-fluid bg-white border-bottom border-donkerblauw pt-2 pb-2"
		>
			<div class="container">
				<div class="row">
					<div class="col text-end">
						<router-link
							:to="{
								name: 'Compare',
								params: { domain: domain.alias },
							}"
							class="button btn-link"
						>
							<strong>Dienstverleners vergelijken?</strong>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<section class="container-fluid pt-5 pb-5">
			<article class="container">
				<div class="row mb-2">
					<div class="col lead fw-normal d-flex">
						<div class="main-ico main-ico-small me-2">
							<i
								class="ro-3x"
								:class="'ro-' + domain.icon_name"
								role="img"
								:alt="domain.name"
								aria-hidden="true"
							/>
						</div>
						<div>
							<h1 class="mb-n1">
								<em>Domein</em>
								{{ domain.name }}
							</h1>
							<span v-if="showMap">Kaartweergave</span>
							<span v-else>Lijstweergave</span>
						</div>
					</div>
					<div
						class="col d-none d-lg-flex align-items-end justify-content-end"
					>
						<button
							class="btn btn-light d-flex align-items-center me-2"
							:class="{ active: showMap }"
							@click="
								(showMap = true),
									(showModal = false),
									(showMinistryModal = false),
									(lastClickedElement = false),
									(lastClickedMinistry = false),
									(activeItem = false),
									(activeMinistry = false)
							"
						>
							<i
								class="ro-interregionaal ro-lg me-1"
								role="img"
								alt="kaart"
								aria-hidden="true"
							/>
							<span class="fw-normal">Kaartweergave</span>
						</button>

						<button
							class="btn btn-light d-flex align-items-center"
							:class="{ active: !showMap }"
							@click="
								(showMap = false),
									(showModal = false),
									(showMinistryModal = false),
									(lastClickedElement = false),
									(lastClickedMinistry = false),
									(activeItem = false),
									(activeMinistry = false)
							"
						>
							<i
								class="ro-menu ro-lg me-1"
								role="img"
								alt="menu"
								aria-hidden="true"
							/>
							<span class="fw-normal">Lijstweergave</span>
						</button>
					</div>
				</div>
				<!-- List-view / mode -->
				<div v-if="!showMap" class="row">
					<div class="col">
						<ul class="list-group list-group-flush border-top mt-4">
							<li
								v-for="provider in listItems"
								:key="provider.id"
								class="list-group-item px-0"
							>
								<h2 class="mb-0">
									{{ provider.name }}
								</h2>

								<!-- place the provider description here -->
								<div
									class="mb-2"
									v-html="provider.description"
								/>

								<!-- levert diensten aan de volgende ministeries -->
								<div>
									<strong
										>{{ provider.name }} levert diensten aan
										de volgende ministeries</strong
									>

									<br />

									<em>
										(klik op een van onderstaande
										ministeries om te zien aan welke
										onderdelen van dit ministerie diensten
										worden geleverd).
									</em>

									<div>
										<button
											v-for="ministry in provider.unit_ministries"
											:key="ministry.id"
											class="btn btn-sm py-0 ministry-button"
											:class="'btn-' + ministry.color"
											@click="
												(activeMinistry = ministry),
													(activeProvider = provider),
													(showMinistryModal =
														showMinistryModal
															? false
															: true)
											"
											@keydown.esc="
												closeEventMinistryModal
											"
										>
											{{ ministry.abbreviation }}
										</button>
									</div>

									<transition name="fade-up">
										<div
											v-show="
												activeProvider == provider &&
												showMinistryModal
											"
											ref="activeProviderBox"
											class="p-2 bg-lighter mt-2 activeProviderBox"
										>
											<button
												class="btn-close"
												aria-label="sluit venster"
												@click.stop="
													closeEventMinistryModal
												"
											>
												<i
													class="ro-kruis"
													role="img"
													alt="sluit venster"
													aria-label="sluit venster"
												/>
											</button>

											<span
												class="btn btn-sm py-0 ministry-button"
												:class="
													'btn-' +
													activeMinistry.color
												"
											>
												{{
													activeMinistry.abbreviation
												}}
											</span>

											<strong
												>Organisatieonderdelen</strong
											>
											<hr class="my-2" />
											<div class="columns">
												<template
													v-for="unit in activeProvider.organisational_units"
													:key="unit.id"
												>
													<div
														v-if="
															activeMinistry.id ==
															unit.fk_ministry
														"
													>
														{{ unit.name }}

														<span v-if="unit.city"
															>({{
																unit.city
															}})</span
														>
													</div>
												</template>
											</div>
										</div>
									</transition>

									<ul
										class="list-group list-group-flush mt-2 mb-3"
									>
										<li
											class="list-group-item p-0 border-0"
										>
											<strong>Vestigingsplaats:</strong>
											{{ joinCities(provider) }}
											<!-- makes a comma separated list -->
										</li>
										<li
											class="list-group-item p-0 border-0"
										>
											<strong>Aantal medewerkers:</strong>
											{{ provider.fte }} fte
										</li>
										<li
											class="list-group-item p-0 border-0"
										>
											<strong>Omzet:</strong>
											&euro; {{ provider.revenue }}
										</li>
										<li
											v-if="provider.category_management"
											class="list-group-item p-0 border-0"
										>
											<strong
												>Categoriemanagement:</strong
											>
											{{ provider.category_management }}
										</li>
										<li
											v-if="provider.website"
											class="list-group-item p-0 border-0 mt-1"
										>
											<a
												target="_blank"
												:href="
													provider.website.indexOf(
														'://'
													) === -1
														? 'https://' +
														  provider.website
														: provider.website
												"
												class="btn btn-primary btn-sm card-link"
											>
												Website {{ provider.name }}
												<span class="visually-hidden"
													>(opent in een nieuw
													venster)</span
												>
												<i
													class="ro-lg ro-externe-link"
												/>
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</div>

				<!-- map-view / mode -->
				<div
					v-else
					id="map"
					class="row"
					role="region"
					aria-label="Kaart met dienstverleners"
				>
					<div class="map-wrapper px-0">
						<div
							class="map-container"
							:class="{ disable: showModal || showMinistryModal }"
							@click.capture="
								showModal || showMinistryModal
									? (closeEventModal, closeEventMinistryModal)
									: ''
							"
						>
							<div
								v-for="city in mapItems"
								:key="city.id"
								class="area"
								:style="{
									left: city.x + '%',
									top: city.y + '%',
								}"
								:class="areaSize(city)"
								:aria-labelledby="'areaid-' + city.id"
							>
								<h2 :id="'areaid-' + city.id">
									{{ city.name }}
								</h2>

								<button
									v-for="provider in city.service_providers"
									:key="provider.id"
									class="marker"
									:aria-label="provider.name"
									:class="{
										activeItem:
											provider == activeProvider &&
											(showModal || showMinistryModal),
										multicolor: provider.colors.length > 1,
									}"
									@click.stop="
										(showModal = true),
											(activeProvider = provider),
											clickedElement($event)
									"
								>
									<i
										v-for="(
											color, index
										) in provider.colors"
										:key="index"
										class="ro-3x"
										:class="[
											'ro-' + domain.icon_name,
											'text-' + color,
										]"
										role="img"
										:aria-label="domain.icon_name"
										:title="'kleur: ' + color"
									/>

									<span>{{ provider.name }}</span>
								</button>
							</div>
						</div>
					</div>
					<FocusTrap
						v-model:active="showModal"
						:initial-focus="() => $refs.closeEventModal"
					>
						<transition name="fade-up">
							<div
								v-if="showModal"
								ref="providerModal"
								v-clickoutside="closeEventModal"
								class="map-modal card"
								tabindex="-1"
								role="dialog"
								aria-labelledby="provider-modal-label"
								aria-describedby="provider-modal-description"
								aria-modal="true"
								@keydown.esc="closeEventModal"
							>
								<div
									id="provider-modal-label"
									class="card-header"
								>
									<i
										v-for="(
											color, indexcolor
										) in activeProvider.color"
										:key="indexcolor"
										:class="[
											{
												multicolor:
													activeProvider.color
														.length > 1,
											},
											'ro-' + domain.icon_name,
											'text-' + color,
										]"
									/>
									<span role="heading">{{
										activeProvider.name
									}}</span>
									<button
										ref="closeEventModal"
										class="btn-close"
										aria-label="sluit venster"
										@click.stop="closeEventModal"
									>
										<i class="ro-kruis" />
									</button>
								</div>
								<div
									id="provider-modal-description"
									class="card-body"
									v-html="activeProvider.description"
								/>

								<ul class="list-group list-group-flush">
									<li
										class="list-group-item border-bottom list-group-item-ministries"
									>
										<span class="text-primary"
											>{{ activeProvider.name }} levert
											diensten aan de volgende
											ministeries</span
										>

										<div>
											<button
												v-for="ministry in ministries"
												:key="ministry.id"
												class="btn btn-ministry"
												:class="[
													'btn-outline-' +
														ministry.color,
													{
														active: providesServices(
															activeProvider,
															ministry
														),
													},
												]"
												:tabindex="
													providesServices(
														activeProvider,
														ministry
													)
														? 0
														: -1
												"
												@click.stop="
													{
														(showMinistryModal = true),
															(showModal = false),
															(activeMinistry =
																ministry),
															clickedMinistry(
																$event
															);
													}
												"
											>
												<svg
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													width="36"
													height="49"
													viewBox="0 0 36 49"
												>
													<title>
														{{
															ministry.abbreviation
														}}
													</title>
													<path
														:class="
															'fill-' +
															ministry.color
														"
														d="M34,0H2A2,2,0,0,0,0,2V49H15V40h6v9H36V2A2,2,0,0,0,34,0ZM11,33H5V27h6Zm0-10H5V17h6Zm0-10H5V7h6ZM21,33H15V27h6Zm0-10H15V17h6Zm0-10H15V7h6ZM31,33H25V27h6Zm0-10H25V17h6Zm0-10H25V7h6Z"
													/>
												</svg>
												<div
													class="badge"
													:class="
														'badge-' +
														ministry.color
													"
												>
													<span
														v-if="
															providesServices(
																activeProvider,
																ministry
															)
														"
														>{{
															ministry.abbreviation
														}}</span
													>
												</div>
											</button>
										</div>
									</li>

									<li class="list-group-item">
										<i class="ro-lg ro-locatie-marker" />
										<div>
											<span>Vestigingsplaats</span>
											<strong>{{
												joinCities(activeProvider)
											}}</strong>
										</div>
									</li>
									<li class="list-group-item">
										<i class="ro-lg ro-groep-mensen" />
										<div>
											<span>Aantal medewerkers</span>
											<strong
												>{{
													activeProvider.fte
												}}
												fte</strong
											>
										</div>
									</li>
									<li class="list-group-item">
										<i class="ro-lg ro-stapeltje-euros" />
										<div>
											<span>Omzet</span>
											<strong
												>&euro;{{
													activeProvider.revenue
												}}</strong
											>
										</div>
									</li>
									<!-- element dat alleen bij inkoop van toepassing is, zal straks wel gewoon uit de dataset worden gehaald -->
									<li
										v-if="
											activeProvider.category_management
										"
										class="list-group-item"
									>
										<i
											class="ro-lg ro-categoriemanagement"
										/>

										<div>
											<span>Categoriemanagement</span>
											<strong>{{
												activeProvider.category_management
											}}</strong>
										</div>
									</li>
								</ul>

								<a
									v-if="activeProvider.website"
									target="_blank"
									:href="
										activeProvider.website.indexOf(
											'://'
										) === -1
											? 'https://' +
											  activeProvider.website
											: activeProvider.website
									"
									class="btn btn-primary btn-sm card-link"
								>
									Website {{ activeProvider.name }}
									<span class="visually-hidden"
										>(opent in een nieuw venster)</span
									>
									<i class="ro-lg ro-externe-link" />
								</a>
							</div>
						</transition>
					</FocusTrap>
					<FocusTrap
						v-model:active="showMinistryModal"
						:initial-focus="() => $refs.closeEventMinistryModal"
					>
						<transition name="fade-up">
							<div
								v-if="showMinistryModal"
								ref="ministryModal"
								v-clickoutside="closeEventMinistryModal"
								class="map-modal card ministry-modal"
								tabindex="-1"
								role="dialog"
								aria-labelledby="ministry-modal-label"
								aria-describedby="ministry-modal-description"
								aria-modal="true"
								@keydown.esc="closeEventMinistryModal"
							>
								<div
									id="ministry-modal-label"
									class="card-header"
								>
									<i
										v-for="(
											color, indexcolor
										) in activeProvider.colors"
										:key="indexcolor"
										:class="[
											{
												multicolor:
													activeProvider.colors
														.length > 1,
											},
											'ro-' + domain.icon_name,
											'text-' + color,
										]"
									/>

									{{ activeProvider.name }}

									<button
										ref="closeEventMinistryModal"
										class="btn-close"
										aria-label="sluit venster"
										@click.stop="closeEventMinistryModal"
									>
										<i class="ro-kruis" />
									</button>
								</div>
								<div
									id="ministry-modal-description"
									class="card-body border-0 d-flex"
								>
									<div class="d-flex flex-column me-2">
										<svg
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											width="36"
											height="49"
											viewBox="0 0 36 49"
										>
											<title>
												{{
													activeMinistry.abbreviation
												}}
											</title>
											<path
												:class="
													'fill-' +
													activeMinistry.color
												"
												d="M34,0H2A2,2,0,0,0,0,2V49H15V40h6v9H36V2A2,2,0,0,0,34,0ZM11,33H5V27h6Zm0-10H5V17h6Zm0-10H5V7h6ZM21,33H15V27h6Zm0-10H15V17h6Zm0-10H15V7h6ZM31,33H25V27h6Zm0-10H25V17h6Zm0-10H25V7h6Z"
											/>
										</svg>

										<span
											class="badge"
											:class="
												'badge-' + activeMinistry.color
											"
											>{{
												activeMinistry.abbreviation
											}}</span
										>
									</div>

									<span class="text-primary fw-bold lead">
										{{ activeProvider.name }} levert
										diensten aan de volgende onderdelen van
										{{ activeMinistry.abbreviation }}
									</span>
								</div>

								<!-- Hier moet uiteraard een lijst gaan komen met dfe gekoppelde units per dienstverlener en niet alle units van het betreffende ministerie. tevens moet er een medlding komen als alles gekoppeld is ipv de hele lijst  -->
								<ul class="text-dark ps-2 mb-0">
									<li
										v-for="(
											unit, index
										) in providerUnitsByMinistry(
											activeProvider,
											activeMinistry
										)"
										:key="index"
									>
										{{ unit.name }}
										<span v-if="unit.city"
											>({{ unit.city }})</span
										>
									</li>
								</ul>
							</div>
						</transition>
					</FocusTrap>
				</div>
			</article>
		</section>
	</main>
</template>

<script>
import { FocusTrap } from "focus-trap-vue";

export default {
	components: {
		FocusTrap: FocusTrap,
	},
	// do not forget this section
	directives: {
		clickoutside: {
			bind(el, binding, vnode) {
				var vm = vnode.context;
				var callback = binding.value;

				el.clickOutsideEvent = function (event) {
					if (!(el == event.target || el.contains(event.target))) {
						return callback.call(vm, event);
					}
				};
				document.body.addEventListener("click", el.clickOutsideEvent);
			},
			unbind(el) {
				document.body.removeEventListener(
					"click",
					el.clickOutsideEvent
				);
			},
		},
	},

	data: function () {
		return {
			// Api data.
			serviceProviders: [],

			showModal: false,
			showMinistryModal: false,
			showMap: true,
			lastClickedElement: "",
			lastClickedMinistry: "",

			activeMinistry: {},
		};
	},

	computed: {
		listItems: function () {
			// Declare the providers (just a pointer eh)
			var serviceProviders = this.serviceProviders;

			// Loop through the providers and extend them
			// with the ministries they serve for easy looping.
			serviceProviders.forEach((provider) => {
				// enter a new property to the provider, unit_ministries.
				provider.unit_ministries = {};

				provider.organisational_units.forEach((unit) => {
					var ministryObj = _.find(this.ministries, {
						id: unit.fk_ministry,
					});

					// Push it to the provider if not known yet.
					if (
						ministryObj &&
						!provider.unit_ministries[ministryObj.id]
					) {
						provider.unit_ministries[ministryObj.id] = ministryObj;
					}
				});
			});

			return serviceProviders;
		},

		mapItems: function () {
			// Declare a variable containing the (unique) cities we find.
			var cities = {};

			var targetCity = "";

			// The map is a bit different, our start point it a city instead of a service provider.
			// We can reuse teh listItems again.
			this.listItems.forEach((provider) => {
				provider.cities.forEach((city) => {
					// lazy iff statement but readable! this is for clustering certain cities
					targetCity = city.id;
					if (targetCity == 21) {
						targetCity = 2;
					} // de bilt with Utrecht
					if (targetCity == 22) {
						targetCity = 2;
					} // bilthoven with Utrecht
					if (targetCity == 19) {
						targetCity = 2;
					} // soesterberg with Utrecht

					if (targetCity == 18) {
						targetCity = 1;
					} // Delft with Den Haag
					if (targetCity == 16) {
						targetCity = 2;
					} // Rijswijk with Den Haag

					if (!cities[targetCity]) {
						// Register object.
						cities[targetCity] = city;
						cities[targetCity].service_providers = {};
					}

					// Append the provider to the city!
					if (!cities[targetCity].service_providers[provider.id]) {
						cities[targetCity].service_providers[provider.id] =
							provider;
					}
				});
			});

			return cities;
		},
	},
	/*
	watch: {
		showModal: function() {
			if (this.showModal) {
				this.$nextTick(() => this.$refs.providerModal.focus());
				this.lastClickedMinistry = false;
			} else {
				this.lastClickedElement.focus();
			}
		},
		showMinistryModal: function() {
			if (this.showMinistryModal) {
				this.$nextTick(() => this.$refs.ministryModal.focus());
			} else {
				this.showModal = true;
			}
		}
	},*/

	created: function () {
		window.addEventListener("resize", this.resizeWindow);
	},

	mounted: function () {
		this.$store
			.dispatch(
				"fetchServiceProvidersFromDomain",
				this.$route.params.domain
			)
			.then((response) => {
				var items = response.data;

				// Loop through the (main) ministries of this provider, to determine his colors.
				items.forEach((item) => {
					item.colors = {};
					var colors = [];
					item.ministries.forEach((ministry) => {
						colors.push(ministry.color);
					});

					item.colors = colors;
				});

				// Store the 'serviceProviders' into a variable.
				// Manupulation via computed methods.
				this.serviceProviders = items;

				if (this.$route.params.providerId) {
					var pid = Number(this.$route.params.providerId);
					var result = _.find(this.serviceProviders, { id: pid });

					if (result) {
						this.showModal = true;
						this.activeProvider = result;
					}
				}
			})
			.catch((error) => {
				this.handleError(error);
			})
			.finally(() => {
				// if loaded on mobile hide filters initially
				if (
					window.matchMedia("screen and (max-width: 992px)").matches
				) {
					this.showMap = false;
				}

				this.toggleLoading();
			});
	},

	updated: function () {},

	methods: {
		resizeWindow: function (e) {
			if (window.innerWidth <= 992) {
				this.showMap = false;
				this.showModal = false;
				this.showMinistryModal = false;
				this.lastClickedElement = false;
				this.lastClickedMinistry = false;
				this.activeItem = false;
				this.activeMinistry = false;
			}
		},
		// checks if a provider is providing services into a certain ministry.
		providesServices: function (provider, ministry) {
			var result = false;
			var ministryId = ministry.id;

			provider.organisational_units.forEach((unit) => {
				if (Number(unit.fk_ministry) == Number(ministryId)) {
					result = true;
				}
			});

			return result;
		},

		providerUnitsByMinistry(provider, ministry) {
			var units = [];
			var ministryId = ministry.id;

			provider.organisational_units.forEach((unit) => {
				if (Number(unit.fk_ministry) == Number(ministryId)) {
					units.push(unit);
				}
			});

			return units;
		},

		areaSize: function (area) {
			var length = Object.keys(area.service_providers).length;

			var size = "normal";
			if (length > 8) {
				size = "large";
			}
			if (length <= 6) {
				size = "small";
			}
			if (length < 5 || area.alias != "den-haag") {
				size = "none";
			}
			return size;
		},
		clickedElement: function (event) {
			this.lastClickedElement = event.currentTarget;
		},
		clickedMinistry: function (event) {
			this.lastClickedMinistry = event.currentTarget;
		},
		closeEventModal: function () {
			if (this.showModal) {
				this.showModal = false;
				if (this.lastClickedElement) {
					this.$nextTick(() => this.lastClickedElement.focus());
				}
			}
		},
		closeEventMinistryModal: function () {
			if (this.showMinistryModal) {
				this.showMinistryModal = false;
				this.showModal = true;
				if (this.lastClickedMinistry) {
					this.lastClickedMinistry.focus();
				}
				this.lastClickedMinistry = false;
			}
		},
	},
};
</script>
