export default {
	data: function () {
		return {
			loading: true,
			activeProvider: {},
		};
	},

	computed: {
		ministries: function () {
			return this.$store.getters.ministries;
		},

		domain: function () {
			if (this.$route.params.domain) {
				return this.$store.getters.domainByAlias(
					this.$route.params.domain
				);
			}

			return false;
		},

		domains: function () {
			return this.$store.getters.domains;
		},
	},

	mounted: function () {},

	methods: {
		toggleLoading: function () {
			this.loading = !this.loading;
		},

		handleError: function () {
			this.$router.push({ name: "ServerError" });
		},

		// makes a comma separated list of the city objects middelburg, hoes, hrieps etc.
		joinCities: function (provider) {
			return provider.cities.map((e) => e.name).join(", ");
		},

		ministryById: function (id) {
			return this.$store.getters.ministryById(id);
		},
	},
};
