<template>
	<main v-if="!loading" id="content">
		<section class="container-fluid pt-5 pb-5">
			<article class="container">
				<div class="lead fw-normal d-flex mb-4">
					<div class="main-ico main-ico-small me-2">
						<i class="ro-3x" :class="'ro-' + domain.icon_name" />
					</div>
					<div>
						<h1 class="mb-n1 alt">Vergelijk dienstverleners</h1>
						<span class="text-primary h4 fw-normal">
							<em>binnen het domein: {{ domain.name }}</em>
						</span>
					</div>
				</div>

				<form @submit.prevent="">
					<div class="row">
						<div class="col">
							<div class="form-group mb-2">
								<label class="form-label" for="compareLeft"
									>dienstverlener</label
								>

								<select
									id="compareLeft"
									v-model="compareLeft"
									class="form-select custom-input"
								>
									<option value="1" selected>
										selecteer een dienstverlener
									</option>
									<template
										v-for="provider in serviceProviders"
										:key="provider.id"
									>
										<option :value="provider">
											{{ provider.name }}
										</option>
									</template>
								</select>
							</div>
						</div>

						<div class="col">
							<div class="form-group mb-2">
								<label class="form-label" for="compareLeft"
									>dienstverlener</label
								>

								<select
									id="compareRight"
									v-model="compareRight"
									class="form-select custom-input"
								>
									<option value="" selected>
										selecteer een dienstverlener
									</option>
									<template
										v-for="provider in serviceProviders"
										:key="provider.id"
									>
										<option :value="provider">
											{{ provider.name }}
										</option>
									</template>
								</select>
							</div>
						</div>
					</div>
					<div class="text-center fs-italic">
						Selecteer 2 dienstverleners om deze te vergelijken.
					</div>
				</form>

				<hr />
				<div v-if="compareLeft && compareRight" class="compare">
					<div class="row">
						<div class="col">
							<h2 class="h3">
								{{ compareLeft.name }}
							</h2>

							<p class="lead">
								{{ compareLeft.description }}
							</p>
						</div>

						<div class="col">
							<h2 class="h3">
								{{ compareRight.name }}
							</h2>

							<p class="lead">
								{{ compareRight.description }}
							</p>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<h3 class="h6">
								{{ compareLeft.name }} levert diensten aan de
								volgende ministeries
							</h3>

							<table class="table table-sm">
								<thead>
									<tr>
										<th scope="col">Ministerie</th>
										<th scope="col">
											Organisatieonderdelen
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="ministry in compareLeft.unit_ministries"
										:key="ministry.id"
									>
										<th scope="row">
											<span
												class="badge"
												:class="
													'badge-' + ministry.color
												"
												>{{
													ministry.abbreviation
												}}</span
											>
										</th>
										<td>
											<div
												v-for="unit in ministry.units"
												:key="unit.id"
											>
												{{ unit.name }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col">
							<h3 class="h6">
								{{ compareRight.name }} levert diensten aan de
								volgende ministeries
							</h3>

							<table class="table table-sm">
								<thead>
									<tr>
										<th scope="col">Ministerie</th>
										<th scope="col">
											Organisatieonderdelen
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="ministry in compareRight.unit_ministries"
										:key="ministry.id"
									>
										<th scope="row">
											<span
												class="badge"
												:class="
													'badge-' + ministry.color
												"
												>{{
													ministry.abbreviation
												}}</span
											>
										</th>
										<td>
											<div
												v-for="unit in ministry.units"
												:key="unit.id"
											>
												{{ unit.name }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="row">
						<div class="col grid grid-3 flex-wrap">
							<div class="compare-block">
								<i class="ro-lg ro-locatie-marker" />
								<div>
									<span>Vestigingsplaats</span>
									<strong>{{
										joinCities(compareLeft)
									}}</strong>
								</div>
							</div>
							<div class="compare-block">
								<i class="ro-lg ro-stapeltje-euros" />
								<div>
									<span>Omzet</span>
									<strong
										>&euro;{{ compareLeft.revenue }}</strong
									>
								</div>
							</div>
							<div class="compare-block">
								<i class="ro-lg ro-groep-mensen" />
								<div>
									<span>Aantal medewerkers</span>
									<strong>{{ compareLeft.fte }} fte</strong>
								</div>
							</div>

							<div
								v-if="compareLeft.category_management"
								class="compare-block"
							>
								<i class="ro-lg ro-categoriemanagement" />
								<div>
									<span>Categoriemanagement</span>
									<strong>{{
										compareLeft.category_management
									}}</strong>
								</div>
							</div>
						</div>
						<div class="col grid grid-3 flex-wrap">
							<div class="compare-block">
								<i class="ro-lg ro-locatie-marker" />
								<div>
									<span>Vestigingsplaats</span>
									<strong>{{
										joinCities(compareRight)
									}}</strong>
								</div>
							</div>
							<div class="compare-block">
								<i class="ro-lg ro-stapeltje-euros" />
								<div>
									<span>Omzet</span>
									<strong
										>&euro;{{
											compareRight.revenue
										}}</strong
									>
								</div>
							</div>
							<div class="compare-block">
								<i class="ro-lg ro-groep-mensen" />
								<div>
									<span>Aantal medewerkers</span>
									<strong>{{ compareRight.fte }} fte</strong>
								</div>
							</div>

							<div
								v-if="compareRight.category_management"
								class="compare-block"
							>
								<i class="ro-lg ro-categoriemanagement" />
								<div>
									<span>Categoriemanagement</span>
									<strong>{{
										compareRight.category_management
									}}</strong>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<a
								v-if="compareLeft.website"
								target="_blank"
								:href="
									compareLeft.website.indexOf('://') === -1
										? 'https://' + compareLeft.website
										: compareLeft.website
								"
								class="text-dark"
							>
								<i
									class="ro-externe-link ro-lg text-primary me-2"
								/>
								{{ compareLeft.name }}
								<span class="visually-hidden"
									>(website opent in een nieuw venster)</span
								>
							</a>
						</div>
						<div class="col">
							<a
								v-if="compareRight.website"
								target="_blank"
								:href="
									compareRight.website.indexOf('://') === -1
										? 'https://' + compareRight.website
										: compareRight.website
								"
								class="text-dark"
							>
								<i
									class="ro-externe-link ro-lg text-primary me-2"
								/>
								{{ compareRight.name }}
								<span class="visually-hidden"
									>(website opent in een nieuw venster)</span
								>
							</a>
						</div>
					</div>
				</div>
			</article>
		</section>
	</main>
</template>

<script>
export default {
	components: {
		// 'kodo-my-component': MyComponent
	},

	props: {
		// myProp:      { type: String, required: true }
		// secondProp:  { type: Number, required: false }
	},

	data: function () {
		return {
			// Api data.
			serviceProviders: [],

			disabled: true,

			compareLeft: false,
			compareRight: false,
		};
	},

	watch: {},

	created: function () {},

	mounted: function () {
		this.$store
			.dispatch(
				"fetchServiceProvidersFromDomain",
				this.$route.params.domain
			)
			.then((response) => {
				var serviceProviders = response.data;

				// Get (all) ministries from the store.
				var ministries = this.ministries;

				// Loop through the providers and extend them
				// with the ministries they serve for easy looping.
				serviceProviders.forEach((provider) => {
					// enter a new property to the provider, unit_ministries.
					provider.unit_ministries = {};

					provider.organisational_units.forEach((unit) => {
						var ministryObj = _.find(this.ministries, {
							id: unit.fk_ministry,
						});

						// Push it to the provider if not known yet.
						if (ministryObj) {
							if (!provider.unit_ministries[ministryObj.id]) {
								var obj = {
									id: ministryObj.id,
									name: ministryObj.name,
									color: ministryObj.color,
									abbreviation: ministryObj.abbreviation,
									units: [],
								};

								provider.unit_ministries[obj.id] = obj;
							}

							provider.unit_ministries[ministryObj.id].units.push(
								unit
							);
						}
					});
				});

				this.serviceProviders = serviceProviders;
			})
			.catch((error) => {
				this.handleError(error);
			})
			.finally(() => {
				this.toggleLoading();
			});
	},

	updated: function () {},

	methods: {},
};
</script>
