<template>
	<div v-if="!loading" id="app">
		<header>
			<div class="logo-wrapper">
				<router-link :to="{ name: 'Home' }" title="Naar de homepagina">
					<img
						src="~@/assets/logo-rijksoverheid.svg"
						alt="Logo Rijksoverheid"
						width="314"
						height="125"
					/>
				</router-link>
			</div>

			<template v-if="$route.name != 'ServerError'">
				<!-- navigation -->
				<nav class="navbar navbar-expand-md navbar-dark bg-donkerblauw">
					<div class="container px-2 py-1 p-md-0">
						<a
							class="skippy visually-hidden visually-hidden-focusable"
							href="#content"
						>
							<span class="skippy-text"
								>Ga direct naar inhoud</span
							>
						</a>

						<button
							class="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span class="navbar-toggler-icon" />
						</button>

						<div
							id="navbarSupportedContent"
							class="collapse navbar-collapse"
						>
							<form
								class="form-inline d-flex d-md-none"
								@submit.prevent="search()"
							>
								<div class="input-group">
									<input
										v-model="searchQuery"
										class="form-control my-auto"
										type="search"
										placeholder="Zoeken en/- filteren"
										title="Type hier uw zoektermen"
										aria-label="Type hier uw zoektermen"
									/>
									<button
										class="btn py-0 btn-dark"
										type="submit"
										title="Start het zoeken"
									>
										<span class="visually-hidden"
											>Zoek</span
										>
										<i
											title="Vergrootglas"
											class="ro-zoek ro-lg"
										/>
									</button>
								</div>
							</form>

							<ul class="navbar-nav me-auto">
								<li class="nav-item dropdown">
									<a
										id="navbarDropdown-domains"
										class="nav-link dropdown-toggle"
										href="#"
										role="button"
										data-bs-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										aria-label="Domeinen"
										title="Alle Domeinen"
									>
										Dienstverleners
									</a>
									<div
										class="dropdown-menu"
										aria-labelledby="navbarDropdown-domains"
									>
										<template
											v-for="(domain, index) in domains"
											:key="index"
										>
											<router-link
												class="dropdown-item"
												:to="{
													name: 'Domain',
													params: {
														domain: domain.alias,
													},
												}"
											>
												{{ domain.name }}
											</router-link>
										</template>
									</div>
								</li>

								<li class="nav-item dropdown">
									<a
										id="navbarDropdown-ministries"
										class="nav-link dropdown-toggle"
										href="#"
										role="button"
										data-bs-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										aria-label="Ministeries"
										title="Alle Ministeries"
									>
										Ministeries
									</a>
									<div
										class="dropdown-menu"
										aria-labelledby="navbarDropdown-ministries"
									>
										<template
											v-for="(
												ministry, index
											) in ministries"
											:key="index"
										>
											<router-link
												class="dropdown-item"
												:to="{
													name: 'Ministry',
													params: {
														ministry:
															ministry.alias,
													},
												}"
											>
												{{ ministry.name }}
											</router-link>
										</template>
									</div>
								</li>

								<li class="nav-item">
									<router-link
										class="nav-link"
										:to="{ path: '/ontwikkelingen' }"
									>
										Ontwikkelingen
									</router-link>
								</li>
								<li class="nav-item">
									<router-link
										class="nav-link"
										:to="{ path: '/toelichting' }"
									>
										Toelichting
									</router-link>
								</li>
							</ul>
						</div>

						<router-link
							class="navbar-brand"
							:to="{ name: 'Home' }"
							title="Naar de homepagina"
						>
							<span class="visually-hidden"
								>Naar de homepagina van</span
							>
							Rijksbrede Dienstverleners
						</router-link>
					</div>
				</nav>

				<kodo-crumbs />
			</template>
		</header>

		<router-view :key="$route.fullPath" />

		<footer class="container-fluid bg-donkerblauw pt-5 pb-5">
			<div class="container">
				<!-- show if not is servererrrrror -->
				<template v-if="$route.name != 'ServerError'">
					<div class="row">
						<div class="col-12 col-md-6 col-lg-4">
							<h2>Ministeries</h2>

							<ul class="list-unstyled">
								<li
									v-for="(ministry, index) in ministries"
									:key="index"
								>
									<router-link
										:to="{
											name: 'Ministry',
											params: {
												ministry: ministry.alias,
											},
										}"
									>
										{{ ministry.name }}
									</router-link>
								</li>
							</ul>
						</div>

						<div class="col-12 col-md-6 col-lg-4">
							<h2>Dienstverleners per domein</h2>

							<ul class="list-unstyled">
								<li
									v-for="(domain, index) in domains"
									:key="index"
								>
									<router-link
										:to="{
											name: 'Domain',
											params: { domain: domain.alias },
										}"
									>
										{{ domain.name }}
									</router-link>
								</li>
							</ul>

							<h2 class="mb">
								<router-link :to="{ path: '/ontwikkelingen' }">
									Ontwikkelingen
								</router-link>
							</h2>

							<h2>
								<router-link :to="{ path: '/toelichting' }">
									Toelichting
								</router-link>
							</h2>
						</div>

						<div class="col-12 col-md-6 col-lg-4">
							<h2>Over deze site</h2>

							<ul class="list-unstyled">
								<li>
									<router-link :to="{ path: '/contact' }">
										Contact
									</router-link>
								</li>
								<li>
									<router-link :to="{ path: '/copyright' }">
										Copyright
									</router-link>
								</li>
								<li>
									<router-link :to="{ path: '/privacy' }">
										Privacy
									</router-link>
								</li>
								<li>
									<router-link :to="{ path: '/cookies' }">
										Cookies
									</router-link>
								</li>
								<li>
									<router-link
										:to="{ path: '/toegankelijkheid' }"
									>
										Toegankelijkheid
									</router-link>
								</li>
							</ul>

							<h2>Links</h2>

							<ul class="list-unstyled">
								<li>
									<a
										target="_blank"
										href="https://www.rijksoverheid.nl"
									>
										www.rijksoverheid.nl
										<span class="visually-hidden"
											>(opent in een nieuw venster)</span
										>
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.rijksoverheid.nl/onderwerpen/rijksoverheid/bedrijfsvoering-van-het-rijk"
									>
										Bedrijfsvoering van het Rijk
										<span class="visually-hidden"
											>(opent in een nieuw venster)</span
										>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</template>

				<hr class="border-primary" />

				<div class="row">
					<div class="col text-end">
						<span class="payoff"
							>De Rijksoverheid. Voor Nederland</span
						>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
// Define the imports.
import KodoCrumbs from "./components/kodo-breadcrumbs.vue";

export default {
	components: {
		// Define components.
		"kodo-crumbs": KodoCrumbs,
	},

	data: function () {
		return {
			// Search query.
			searchQuery: "",
			loading: true,
		};
	},

	computed: {},

	beforeUpdate: function () {},

	updated: function () {},

	mounted: function () {
		document.title = this.$appName;
		Promise.all([
			this.$store.dispatch("loadDomains"),
			this.$store.dispatch("loadMinistries"),
		])
			.catch((error) => {
				this.handleError(error);
			})
			.finally(() => {
				this.toggleLoading();
			});

		// @todo push to 500 on error.
	},

	methods: {
		search: function () {
			this.$router.push("/zoeken?q=text=" + this.searchQuery);
		},
	},
};
</script>

<style lang="scss">
@import "@/scss/app.scss";
</style>
