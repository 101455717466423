import { createRouter, createWebHistory } from "vue-router";

// Import the custom components (main) areas.
import Home from "./views/home.vue";
import Domain from "./views/domain.vue";
import Compare from "./views/compare.vue";
import Search from "./views/search.vue";
import ServerError from "./views/500.vue";
import Ministry from "./views/ministry.vue";
import Page from "./views/page.vue";

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),

	routes: [
		// Basic home route
		{ path: "/", name: "Home", component: Home },

		// Basic search route
		{ path: "/zoeken", name: "Search", component: Search },

		// Route to a domain
		{
			path: "/domein/:domain/:providerId?",
			name: "Domain",
			component: Domain,
		},
		{
			path: "/domein/:domain/dienstverleners-vergelijken",
			name: "Compare",
			component: Compare,
		},

		// Route to a ministry
		{
			path: "/ministerie/:ministry",
			name: "Ministry",
			component: Ministry,
		},

		{ path: "/500", name: "ServerError", component: ServerError },

		{ path: "/:pathMatch(.*)*", component: Page },
	],

	scrollBehavior() {
		return { left: 0, top: 0 };
	},
});

export default router;
