<template>
	<main v-if="!loading" id="content" class="bg-map">
		<section class="container-fluid pt-5 pb-5">
			<article class="container">
				<h1>Interne server foutmelding</h1>
				<p>
					Er ging iets mis bij het bezoeken van deze pagina. Probeer
					het later opnieuw. Onze excuses voor het ongemak.
				</p>
			</article>
		</section>
	</main>
</template>

<script>
export default {
	components: {
		// 'kodo-my-component': MyComponent
	},

	props: {
		// myProp:      { type: String, required: true }
		// secondProp:  { type: Number, required: false }
	},

	data: function () {
		return {};
	},

	computed: {},

	watch: {},

	created: function () {},

	mounted: function () {
		this.toggleLoading();
	},

	methods: {},
};
</script>
