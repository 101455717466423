<template>
	<main v-if="!loading" id="content" class="bg-map">
		<section class="container-fluid pt-5">
			<article class="container">
				<h1>
					<em>Rijksbrede</em>
					dienstverleners
				</h1>

				<!--p class="lead">
					Welkom op de website van de Rijksbrede dienstverlening. Deze website geeft een overzicht van de
					rijksbrede dienstverleners binnen het brede bedrijfsvoeringsdomein.<br><br>
					Om goed te kunnen werken maken alle medewerkers van de Rijksoverheid gebruik van
					ondersteuning op maat. Denk hierbij bijvoorbeeld aan ICT-voorzieningen, schoonmaak en
					beveiliging en een mogelijkheid om te reizen. Verschillende Rijksbrede dienstverleners zetten zich
					in om deze ondersteuning te bieden zoals departementale organisatieonderdelen, shared service
					organisaties (SSO’s), samenwerkingsverbanden, concern dienstverleners (CDV’s) en andere
					rijksbrede uitvoeringsorganisaties.<br><br>
					Op de landkaart van Nederland die op deze pagina staat wordt zichtbaar waar de dienstverleners
					zich bevinden (vestigingsplaats), voor wie zij werken (organisaties en -onderdelen) en onder welke
					organisatie zij ressorteren.<br><br>
					Voor een uitgebreide toelichting op de rijksbrede dienstverleners <router-link to="toelichting">klik je hier.</router-link>
				</p-->

				<!--p class="lead">
                    Om goed te kunnen werken, maken alle medewerkers van de Rijksoverheid gebruik van ondersteunende diensten.<br>
                    Denk hierbij aan kantoorpanden, een schone werkplek, ICT-voorzieningen en mogelijkheden om te vergaderen en te reizen.<br>
                    Verschillende rijksbrede dienstverleners zetten zich in om deze ondersteuning te bieden.<br>
                    Deze website geeft een overzicht van deze dienstverleners.<br>
                    Op een landkaart van Nederland is per bedrijfsvoeringsdomein te zien waar deze dienstverleners zich bevinden (vestigingsplaats), voor wie zij werken (organisaties en -onderdelen) en onder welk ministerie zij vallen.
                    <br><br>
                    Voor een uitgebreide toelichting op de rijksbrede dienstverleners  <router-link to="toelichting">klik hier.</router-link>.
                </p-->

				<p class="lead">
					<strong
						>Om goed te kunnen werken, maken alle medewerkers van de
						Rijksoverheid gebruik van ondersteunende diensten. Denk
						hierbij aan kantoorpanden, een schone werkplek,
						ICT-voorzieningen en mogelijkheden om te vergaderen en
						te reizen. Verschillende rijksbrede dienstverleners
						zetten zich in om deze ondersteuning te bieden.</strong
					>
				</p>
				<p class="lead">
					<strong
						>Deze website geeft een overzicht van deze
						dienstverleners. Op een landkaart van Nederland is per
						bedrijfsvoeringsdomein te zien waar deze dienstverleners
						zich bevinden (vestigingsplaats), voor wie zij werken
						(organisaties en -onderdelen) en onder welk ministerie
						zij vallen.</strong
					>
				</p>
				<p class="lead">
					<strong
						>Voor een uitgebreide toelichting op de rijksbrede
						dienstverleners&nbsp;</strong
					>
					<router-link to="toelichting">
						<strong>klik hier</strong>
					</router-link>
					.
				</p>

				<!--button
				data-bs-toggle="modal"
				data-bs-target="#colofon-modal"
				class="btn btn-link float-end"
			>
				Colofon
			</button-->
			</article>
		</section>

		<section>
			<div class="container pt-3 pb-5">
				<div class="row">
					<router-link
						v-for="(domain, index) in domains"
						:key="index"
						class="main-ico col-6 col-md-4 col-lg-2 text-center mt-3"
						:to="{
							name: 'Domain',
							params: { domain: domain.alias },
						}"
					>
						<i
							class="ro-4x"
							:class="'ro-' + domain.icon_name"
							role="img"
							aria-hidden="true"
							:alt="domain.name"
						/>
						<div>
							<strong class="mt-2">{{ domain.name }}</strong>
						</div>
					</router-link>
				</div>
			</div>
		</section>

		<!--aside
			class="modal"
			tabindex="-1"
			role="dialog"
			id="colofon-modal"
			aria-labelledby="Colofon"
			aria-describedby="Colofon Rijksbrededienstverleners"
		>
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h2 class="modal-title">Colofon</h2>
						<button
							type="button"
							class="close"
							data-bs-dismiss="modal"
							aria-label="Sluiten"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<h3>
							Landschapskaart Dienstverlening Rijksbrede
							Bedrijfsvoering
						</h3>

						<p>
							<strong>Publicatie</strong><br />
							zomer 2017
						</p>

						<p>
							<strong>Productie</strong><br />
							Ministerie van Binnenlandse Zaken en
							Koninkrijksrelaties<br />
							DG OverheidsOrganisatie<br />
							Directie Ambtenaar & Organisatie<br />
							Doorontwikkeling Rijksbrede Bedrijfsvoering
						</p>

						<p>
							<strong>Bronnen</strong><br />
							De landschapskaart is tot stand gekomen op basis van
							informatie zoals door de ministeries is aangeleverd.
						</p>

						<p>
							<strong>E-mail</strong><br />
							<a
								href="mailto:drb@rijksoverheid.nl"
								title="stuur een e-mail naar: drb@rijksoverheid.nl"
								>drb@rijksoverheid.nl</a
							>
						</p>

						<p>
							Ester Boetzkes<br />
							Daphne Lureman
						</p>
					</div>
				</div>
			</div>
		</aside-->
	</main>
</template>

<script>
// Define the imports.
//import KodoIcon from "./../components/kodo-icon.vue";

export default {
	components: {
		// Define components.
		//"kodo-icon": KodoIcon
	},

	props: {
		// myProp:      { type: String, required: true }
		// secondProp:  { type: Number, required: false }
	},

	data: function () {
		return {};
	},

	computed: {
		domains: function () {
			return this.$store.getters.domains;
		},
	},

	watch: {},

	created: function () {},

	mounted: function () {
		// Finish loading.
		this.toggleLoading();
	},

	updated: function () {},

	methods: {},
};
</script>
