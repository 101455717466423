<template>
	<main id="content">
		<section class="container-fluid pt-5 pb-5">
			<div class="container">
				<h1 class="text-dark mb-4">
					{{ resultCount }} zoekresultaten:
				</h1>
				<div class="row">
					<div class="col">
						<transition name="fade">
							<button
								v-if="searchParams.text"
								class="btn btn-white text-grijs-5 float-start"
							>
								Trefwoord: {{ searchParams.text }}
								<i
									class="ro-kruis ms-1"
									@click="searchParams.text = ''"
								/>
							</button>
						</transition>
					</div>
					<div class="col">
						<button
							class="btn btn-light text-dark bg-grijs-1 border-grijs-1 float-end"
							type="button"
							aria-expanded="false"
							@click="showFilters = !showFilters"
						>
							<template v-if="showFilters">
								Verberg filters
								<i class="ro-delta-omhoog ms-1 text-grijs-5" />
							</template>
							<template v-else>
								Toon filters
								<i class="ro-delta-omlaag ms-1 text-grijs-5" />
							</template>
						</button>
					</div>
				</div>

				<div class="bg-gray border-top border-grijs-5 mb-3">
					<transition name="fade">
						<form
							v-if="showFilters"
							class="px-3 py-2 border-bottom border-grijs-5"
							@submit.prevent="showResults = true"
						>
							<div class="mb-2">
								<label class="form-label" for="trefwoord"
									>Trefwoord</label
								>

								<!-- @martijn, autofocus toegepast en het is verplicht om 1 trefwoord in te voeren -->
								<input
									id="trefwoord"
									v-model="searchParams.text"
									required
									type="text"
									class="form-control custom-input"
									placeholder="Zoeken"
									autofocus
								/>
							</div>

							<div class="row">
								<div class="col-12 col-md-4">
									<label
										class="form-label"
										for="search_domain"
										>Domein</label
									>
									<select
										id="domain_search"
										v-model="searchParams.domain"
										class="form-select custom-input mb-3"
										title="Selecteer een domein"
									>
										<option :value="''" selected>
											Alle domeinen
										</option>
										<option
											v-for="domain in domains"
											:key="domain.id"
											:value="domain.id"
										>
											{{ domain.name }}
										</option>
									</select>
								</div>

								<div class="col-12 col-md-4">
									<label
										class="form-label"
										for="search_ministry"
										>Ministerie</label
									>
									<select
										id="search_ministry"
										v-model="searchParams.ministry"
										class="form-select custom-input mb-3"
										title="Selecteer een ministerie"
										@change="fetchMinistryUnits()"
									>
										<option :value="''">
											Alle ministeries
										</option>
										<option
											v-for="ministry in ministries"
											:key="ministry.id"
											:value="ministry.id"
										>
											{{ ministry.name }}
										</option>
									</select>
								</div>

								<div class="col-12 col-md-4">
									<label class="form-label" for="search_unit"
										>Organisatieonderdeel</label
									>
									<select
										id="search_unit"
										v-model="searchParams.unit"
										:title="searchUnitTitle"
										:disabled="!searchParams.ministry"
										class="form-select custom-input mb-3"
									>
										<option selected :value="''">
											Alle onderdelen
										</option>
										<option
											v-for="unit in ministryUnits"
											:key="unit.id"
											:value="unit.id"
										>
											{{ unit.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="text-center">
								<button
									type="submit"
									class="btn btn-outline-primary text-primary bg-white px-4 my-1 mx-auto"
									@click="search"
								>
									Zoeken
								</button>
							</div>
						</form>
					</transition>
				</div>

				<div v-if="resultCount == 0">
					Er zijn geen resultaten gevonden
				</div>

				<div v-else>
					<template
						v-if="
							searchResults.pages &&
							searchResults.pages.length > 0
						"
					>
						<!-- zoekresultaat standaard pagina layout -->
						<strong class="d-block mt-4"
							>Zoekresultaten standaard pagina's</strong
						>

						<ul class="list-group list-group-flush border-top">
							<li
								v-for="page in searchResults.pages"
								:key="page.id"
								class="list-group-item px-0"
							>
								<h2>{{ page.name }}</h2>
								<div class="mb-2">
									{{
										truncate(
											plain_text(page.content),
											280,
											"..."
										)
									}}
								</div>
								<router-link
									:to="'/' + page.alias"
									class="btn btn-primary"
								>
									ga naar {{ page.name }}
								</router-link>
							</li>
						</ul>
					</template>

					<template
						v-if="
							searchResults.service_providers &&
							searchResults.service_providers.length > 0
						"
					>
						<!-- zoekresultaat Dienstverleners -->
						<strong class="d-block mt-4"
							>Zoekresultaten Dienstverleners</strong
						>
						<ul class="list-group list-group-flush border-top">
							<li
								v-for="provider in searchResults.service_providers"
								:key="provider.id"
								class="list-group-item px-0"
							>
								<h2 class="mb-0">
									{{ provider.name }}
								</h2>

								<!-- place the provider description here -->
								<div class="mb-2">
									{{ provider.description }}
								</div>

								<!-- levert diensten aan de volgende ministeries -->
								<div>
									<strong
										>{{ provider.name }} levert diensten aan
										de volgende ministeries</strong
									>
									<br />
									<em
										>(klik op een van onderstaande
										ministeries om te zien aan welke
										onderdelen van dit ministerie diensten
										worden geleverd).</em
									>

									<div v-if="provider.ministries_and_units">
										<button
											v-for="ministry in provider.ministries_and_units"
											:key="ministry.id"
											class="btn btn-sm py-0 ministry-button"
											:class="'btn-' + ministry.color"
											@click="
												(activeMinistry = ministry),
													(activeProvider = provider),
													(showMinistryModal = true)
											"
										>
											{{ ministry.abbreviation }}
										</button>
									</div>

									<transition name="fade-up">
										<div
											v-show="
												activeProvider == provider &&
												showMinistryModal
											"
											ref="activeProviderBox"
											class="p-2 bg-light mt-2 activeProviderBox"
										>
											<button
												class="btn-close"
												@click="
													showMinistryModal = false
												"
											>
												<i class="ro-kruis" />
											</button>
											<span
												class="btn btn-sm py-0 ministry-button"
												:class="
													'btn-' +
													activeMinistry.color
												"
											>
												{{
													activeMinistry.abbreviation
												}}
											</span>

											<strong
												>Organisatieonderdelen</strong
											>
											<hr class="my-2" />
											<div class="columns">
												<div
													v-for="unit in activeMinistry.units"
													:key="unit.id"
												>
													{{ unit.name }}
												</div>
											</div>
										</div>
									</transition>

									<ul
										class="list-group list-group-flush mt-2 mb-3"
									>
										<li
											class="list-group-item p-0 border-0"
										>
											<strong>Vestigingsplaats:</strong>
											{{ joinCities(provider) }}
											<!-- makes a comma separated list -->
										</li>
										<li
											class="list-group-item p-0 border-0"
										>
											<strong>Aantal medewerkers:</strong>
											{{ provider.fte }} fte
										</li>
										<li
											class="list-group-item p-0 border-0"
										>
											<strong>Omzet:</strong>
											&euro; {{ provider.revenue }}
										</li>
										<li
											v-if="provider.category_management"
											class="list-group-item p-0 border-0"
										>
											<strong
												>Categoriemanagement:</strong
											>
											{{ provider.category_management }}
										</li>
										<li
											v-if="provider.website"
											class="list-group-item p-0 border-0 mt-1"
										>
											<a
												target="_blank"
												:href="
													provider.website.indexOf(
														'://'
													) === -1
														? 'https://' +
														  provider.website
														: provider.website
												"
												class="btn btn-primary btn-sm card-link"
											>
												Website
												{{ provider.name }}
												<span class="visually-hidden"
													>(opent in een nieuw
													venster)</span
												>
												<i
													class="ro-lg ro-externe-link"
												/>
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</template>

					<template
						v-if="
							searchResults.organisational_units &&
							searchResults.organisational_units.length > 0
						"
					>
						<!-- zoekresultaat Organisatieonderdeel layout -->
						<strong class="d-block mt-4"
							>Zoekresultaten voor Organisatieonderdelen</strong
						>

						<ul
							v-for="unit in searchResults.organisational_units"
							:key="unit.id"
							class="list-group list-group-flush border-top"
						>
							<li class="list-group-item px-0">
								<h2>Organisatieonderdeel {{ unit.name }}</h2>
								<div class="row">
									<div
										v-for="domain in unit.domains"
										:key="domain.id"
										class="col-12 col-md-4"
									>
										<div class="d-flex mt-2 mb-3">
											<i
												:class="
													'ro-' +
													domain.icon_name +
													' ro-icon-circle'
												"
											/>

											<div>
												<h3 class="h4 mb-0">
													<router-link
														:to="{
															name: 'Domain',
															params: {
																domain: domain.alias,
															},
														}"
													>
														Domein {{ domain.name }}
													</router-link>
												</h3>

												<div
													v-for="(
														sp, index
													) in domain.service_providers"
													:key="index"
												>
													<router-link
														class="text-dark"
														:to="{
															name: 'Domain',
															params: {
																domain: domain.alias,
																providerId:
																	sp.id,
															},
														}"
													>
														{{ sp.name }}
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</template>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	components: {
		// Define components.
		//"kodo-icon": KodoIcon
	},

	data: function () {
		return {
			// Search query.
			searchParams: {
				text: "",
				ministry: "",
				domain: "",
				unit: "",
			},

			searchResults: {
				pages: [],
				units: [],
				service_providers: [],
			},

			ministryUnits: [],

			text: "",
			showFilters: true,
			showResults: false,
			activeProvider: "",
			activeMinistry: "",
			showMinistryModal: false,
		};
	},

	computed: {
		searchUnitTitle: function () {
			if (this.searchParams.ministry)
				return "Selecteer een organisatieonderdelen";
			return "Selecteer eerst een ministerie";
		},

		resultCount: function () {
			var count = 0;

			if (this.searchResults.pages) {
				count += this.searchResults.pages.length;
			}

			if (this.searchResults.organisational_units) {
				count += this.searchResults.organisational_units.length;
			}

			if (this.searchResults.service_providers) {
				count += this.searchResults.service_providers.length;
			}

			return count;
		},
	},

	beforeUpdate: function () {},

	updated: function () {},

	mounted: function () {
		this.toggleLoading();

		// if loaded on mobile hide filters initially
		if (window.matchMedia("screen and (max-width: 768px)").matches) {
			this.showFilters = false;
		}

		var params = new URLSearchParams(this.$route.query.q);

		if (params.get("text")) {
			this.searchParams.text = params.get("text");
		}
		if (params.get("ministry")) {
			this.searchParams.ministry = params.get("ministry");
		}
		if (params.get("domain")) {
			this.searchParams.domain = params.get("domain");
		}

		if (params.get("ministry")) {
			this.fetchMinistryUnits();
		}

		if (params.get("unit")) {
			this.searchParams.unit = params.get("unit");
		}

		// Execute search.
		this.$store
			.dispatch("fetchSearchResults", this.searchParams)
			.then((response) => {
				this.searchResults = response.data;
			})
			.catch((error) => {
				//this.handleError(error);
			})
			.finally(() => {
				this.toggleLoading();
			});
	},

	methods: {
		plain_text: function (text) {
			return text.replace(/<\/?[^>]+(>|$)/g, "");
		},
		truncate: function (text, length, suffix) {
			return text.substring(0, length) + suffix;
		},
		search: function () {
			var query = [];
			// Glue search params.
			for (var param in this.searchParams) {
				if (this.searchParams.hasOwnProperty(param)) {
					if (this.searchParams[param]) {
						query.push(
							encodeURIComponent(param) +
								"=" +
								encodeURIComponent(this.searchParams[param])
						);
					}
				}
			}

			this.$router.push({
				name: "Search",
				query: { q: query.join("&") },
			});
		},

		fetchMinistryUnits: function () {
			var ministry = this.ministryById(
				Number(this.searchParams.ministry)
			);

			this.searchParams.unit = "";

			if (ministry && ministry.units) {
				this.ministryUnits = ministry.units;
			} else {
				this.ministryUnits = [];
			}
		},
	},
};
</script>
