// Import axios (node modules)
import axios from "axios";

//const API_URL = VUE_APP_API_URL;
const API_URL = process.env.VUE_APP_API_URL;

export default axios.create({
	baseURL: API_URL,
	//withCredentials: true,
	headers: {
		"Content-Type": "application/json",
	},
});
