<template>
	<nav class="bg-light" aria-label="breadcrumb">
		<div class="container p-0 d-flex">
			<ol v-if="crumbs.length" class="breadcrumb me-auto py-1 py-md-2">
				<!-- v-if="!$route.params.providerId" -->

				<li
					v-for="(crumb, i) in crumbs"
					:key="i"
					class="breadcrumb-item"
				>
					<router-link v-if="i < crumbs.length - 1" :to="crumb.path">
						{{ crumb.name }}
					</router-link>
					<template v-else>
						{{ crumb.name }}
					</template>
				</li>
			</ol>

			<form
				class="form-inline d-none d-md-flex"
				@submit.prevent="search()"
			>
				<div class="input-group">
					<input
						v-model="searchQuery"
						class="form-control my-auto"
						type="search"
						placeholder="Zoeken en/- filteren"
						title="Type hier uw zoektermen"
						aria-label="Type hier uw zoektermen"
					/>
					<button
						class="btn py-0 text-donkerblauw"
						type="submit"
						title="Start het zoeken"
					>
						<span class="visually-hidden">Zoek</span>
						<i title="Vergrootglas" class="ro-zoek ro-lg" />
					</button>
				</div>
			</form>
		</div>
	</nav>
</template>

<script>
export default {
	components: {},

	props: {
		root: {
			type: String,
			default: "",
		},
	},

	data: function () {
		return {
			searchQuery: "",
		};
	},

	computed: {
		crumbs: function () {
			var path = "",
				title = this.root || "home";

			var cs = [{ name: title, path: "/" }];
			if (!this.$route) return [];

			var r = this.$route.path.split("/");
			var m = (this.$route.matched[0].meta.crumbs || "").split("/");

			for (var i = 1; i < r.length; i++) {
				var name = m[i] || r[i];
				if (r[i] == "") continue;

				title += " : " + name;
				path += "/" + name;

				cs.push({ name: name, path: path });
			}

			// window.document.title = title;
			cs = cs.filter((crumb) => {
				return (
					crumb.name !== "domein" &&
					crumb.name !== "ministerie" &&
					crumb.name !== this.$route.params.providerId
				);
			});

			return cs;
		},
	},

	beforeUpdate: function () {},

	updated: function () {},

	mounted: function () {},

	methods: {
		search: function () {
			this.$router.push("/zoeken?q=text=" + this.searchQuery);
		},
	},
};
</script>
