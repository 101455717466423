<template>
	<main v-if="!loading" id="content" class="bg-map">
		<section class="container-fluid pt-5 pb-5">
			<article class="container">
				<h1 class="alt">Ministerie van {{ ministry.name }}</h1>

				<button
					class="btn btn-light text-grijs-6 bg-white border-white mt-n3 float-end"
					type="button"
					aria-expanded="false"
					@click="showFilters = !showFilters"
				>
					<template v-if="showFilters">
						Verberg filters
						<i class="ro-delta-omhoog ms-1 text-primary" />
					</template>
					<template v-else>
						Toon filters
						<i class="ro-delta-omlaag ms-1 text-primary" />
					</template>
				</button>
				<div class="border border-primary bg-white p-2 mt-3 mb-4">
					<div class="d-flex flex-row align-items-center">
						<h2 class="h4 m-0">
							Organisatie onderdelen {{ ministry.abbreviation }}
						</h2>

						<div class="form-check ms-auto d-none d-md-block">
							<input
								id="all"
								type="checkbox"
								class="form-check-input"
								:checked="selectAllUnits"
								@click="toggleSelectAll"
							/>
							<label
								class="form-check-label text-primary fw-bold"
								for="all"
								>Alle onderdelen selecteren</label
							>
						</div>
					</div>
					<transition name="fade">
						<div
							v-if="showFilters"
							class="row mx-0 border-top border-light mt-2 pt-2"
						>
							<div
								v-for="(unit, index) in ministry.units"
								:key="unit.id"
								class="form-check col-12 col-md-6"
							>
								<input
									:id="index"
									v-model="selectedUnits"
									type="checkbox"
									class="form-check-input"
									:value="unit.id"
								/>
								<label class="form-check-label" :for="index">{{
									unit.name
								}}</label>
							</div>
						</div>
					</transition>
				</div>

				<p>
					<strong class="h5 text-dark"
						>Het ministerie van {{ ministry.name }} krijgt diensten
						geleverd van de onderstaande dienstverleners.</strong
					>
				</p>

				<div class="row">
					<div
						v-for="(d, index) in domainsAndServiceProviders"
						:key="index"
						class="col-12 col-md-6"
					>
						<div class="d-flex mt-2 mb-3">
							<i
								:class="'ro-' + d.icon_name + ' ro-icon-circle'"
							/>

							<div>
								<h3 class="h4 mb-0">
									<router-link
										:to="{
											name: 'Domain',
											params: { domain: d.alias },
										}"
									>
										Domein {{ d.name }}
									</router-link>
								</h3>

								<div
									v-for="(sp, spindex) in d.service_providers"
									v-show="activeUnit(sp)"
									:key="spindex"
								>
									<router-link
										class="text-dark"
										:to="{
											name: 'Domain',
											params: {
												domain: d.alias,
												providerId: sp.id,
											},
										}"
									>
										{{ sp.name }}
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</article>
		</section>
	</main>
</template>

<script>
import _ from "lodash";
export default {
	components: {
		// 'kodo-my-component': MyComponent
	},

	props: {
		// myProp:      { type: String, required: true }
		// secondProp:  { type: Number, required: false }
	},

	data: function () {
		return {
			showFilters: true,
			ministry: null,
			selectedUnits: [],
			selectAllUnits: false,
		};
	},

	computed: {
		// Computed method to get the domains + service provider a complex merge of objects
		// that are deeply linked to each other.
		domainsAndServiceProviders: function () {
			var data = {};

			this.$store.getters.domains.forEach((domain) => {
				this.ministry.units.forEach((unit) => {
					unit.service_providers.forEach((sp) => {
						var key = Number(domain.id);
						if (!data[key]) {
							// the base object is the domain.
							data[key] = domain;

							// extend each domain with service providers.
							data[key].service_providers = [];

							// extend each domain with units.
							data[key].units = [];
						}

						if (Number(sp.fk_domain) == Number(domain.id)) {
							// Extend the service provider with the unit-id
							var result = _.find(data[key].service_providers, {
								id: sp.id,
							});
							if (!result) {
								// Extend the object a little.
								sp.units = [];
								sp.units.push(unit.id);

								data[key].service_providers.push(sp);
							} else {
								if (!_.includes(result.units, unit.id)) {
									result.units.push(unit.id);
								}
							}
						}
					});
				});
			});

			return data;
		},
	},

	watch: {},

	created: function () {},

	mounted: function () {
		this.$store
			.dispatch(
				"fetchMinistryOrganisationalUnits",
				this.$route.params.ministry
			)
			.then((response) => {
				this.ministry = response.data;
				this.toggleSelectAll();
			})
			.catch((error) => {
				this.handleError(error);
			})
			.finally(() => {
				// if loaded on mobile hide filters initially
				if (
					window.matchMedia("screen and (max-width: 768px)").matches
				) {
					this.showFilters = false;
				}

				this.toggleLoading();
			});
	},

	updated: function () {},

	methods: {
		// Determines if the unit is active (checkbox selected)
		activeUnit: function (sp) {
			for (var i = 0; i < sp.units.length; i++) {
				if (this.selectedUnits.includes(sp.units[i])) return true;
			}

			return false;
		},

		toggleSelectAll: function () {
			// Toggle.
			this.selectAllUnits = !this.selectAllUnits;

			if (this.selectAllUnits) {
				var tmpUnits = [];

				// Loop through all units and put the ids in the array.
				this.ministry.units.forEach((unit) => {
					tmpUnits.push(unit.id);
				});

				// Select all.
				this.selectedUnits = tmpUnits;
			} else {
				// None selected.
				this.selectedUnits = [];
			}
		},
	},
};
</script>
