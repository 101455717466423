import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import "bootstrap";

// import KodoFilters from "./filters";
import KodoMixins from "./mixins";

const app = createApp(App);

// Use global filters as global properties
// for (const [name, filter] of Object.entries(KodoFilters)) {
// 	app.config.globalProperties[name] = filter;
// }

app.config.globalProperties.$appName = process.env.VUE_APP_TITLE;

// Use global mixins
app.mixin(KodoMixins);

app.use(router);
app.use(store);

app.mount("#app");

// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')
